import  "./Home.css"
import React, { Component } from 'react';
import Carousel from 'react-bootstrap/Carousel';
import PanellhniaPrwtathlhmataCarousel from "./PanellhniaPrwtathlhmataCarousel"

const Home = ()=>{
    const backgroundImageStyle ={
        width:"100%",
    }
    const donateBtnStyle ={
        padding:"10px",
        borderRadius:"20px",
        borderColor:"pink",
        backgroundColor:"pink",

    }
    const menuShrinkTransition=(e)=>{
        // document.getElementById("PricingBtn").style.display="block"
        // document.getElementById("DisabledBtn").style.display="block"
    }
    const menuExpandTransition=(e)=>{
        // document.getElementById("PricingBtn").style.display="none"
        // document.getElementById("DisabledBtn").style.display="none"
    }
    const gotoIPTTFProfile=(e)=>{
        console.log(e)
        window.location.href="http://stats.ipttc.org/en/profiles/6593";
    }
        
    const hiddenBtn={
       display:"none"
    }
    
    return (
    <>
    <div class="bodyContainer">
    <div class=" headerContainer p-0">
        <nav class="navbar navbar-expand-lg navbar-light d-flex flex-row-reverse"  id="menuBar">
                <div class="navbar-nav"  onMouseEnter={menuShrinkTransition} onMouseLeave={menuExpandTransition}>
                    <a class="nav-item nav-link active" id="homeBtn" href="/">Αρχική <span class="sr-only">(current)</span></a>
                    <a class="nav-item nav-link" id="iPoreiaMouBtn" href="/IPoreiaMou">Η πορεία μου</a>
                    <a class="nav-item nav-link" id="PricingBtn" href="/" style={hiddenBtn}>Pricing</a>
                    <a class="nav-item nav-link" id="DisabledBtn" href="/" style={hiddenBtn}>Disabled</a>
                    <a class="nav-item nav-link" id="donateBtn" style={donateBtnStyle}  href="/Donate"> Donate</a>
                </div>
            </nav> 
        </div>
        <div class="homeContainer">
            <img src="/images/Home/SingaporeSmash.jpeg" alt="Singapore Smash" style={backgroundImageStyle}/> 
            <div class="row logoContainer p-4">
                <h1 class="logoName p-2">Καΐπη Μαρία</h1>
                <h3>Πρωταθλήτρια Ελλάδος στην επιτραπέζια αντισφαίριση </h3>
                {/* <div class="row">
                    <div class="col text-end">
                        <h5>25 χρονών</h5>
                    </div>
                </div> */}
            </div>        
        </div>
        <div className="page">
            <div class="row " >
                <div class="col-5 d-flex px-md-5 p-2 justify-content-center">
                    <img  src="/images/Home/SingaporeSmashProfile.png" alt="Singapore Smash Profile"/>
                </div>
                <div class="col d-flex px-md-5 justify-content-start p-5">
                    <div class="p-3 border rounded p-5 mr-5 text-center infoContent">
                        <div class="row " onClick={gotoIPTTFProfile} role="button">
                            <div class="col-lg-2 col-md-3 col-sm-4 col-xs-4">
                                <a href="http://stats.ipttc.org/en/profiles/6593" title="Back to ITTF PTT home page">
                                    <div class="ptt-logo">
                                    </div>
                                </a>
                            </div>
                            <div class="col align-self-center text-start" >
                                <h1>KAIPI Maria Ismini </h1>
                            </div>
                        </div>
                        <div class="row text-start pt-4">
                            <h3>Woman, class 7 (Confirmed), 25 years old</h3>
                            <h3>Best rank #14 in class 7 was on May 2023.</h3>
                            {/* <h3>Ranked # in class 7 and # in women standing.</h3> */}
                            <a href="/IporeiaMou/tournaments/GreekParaOpen2022" class="h4 text-start mt-2">Last tournament: 2023 ITTF Greek Para Open</a>
                            <a  href="/IporeiaMou/tournaments/MontenegrinParaChampionships2022" class="h4 text-start">First tournament: 2022 Montenegrin Para Championships </a>
                            <h3 class="text-start">Minor medals (1)
                            3 2023 ITTF Greek Para Open Bronze in Mixed doubles class 14  </h3>
                        </div>
                        
                    </div>
                </div>
                <div class="row  text-center mt-5">
                    <h1 >Πρωταθλήματα Ελλάδος</h1>
                </div>
                <div class="row justify-content-center mt-5" >
                    <PanellhniaPrwtathlhmataCarousel/>
                </div>
            </div>
            <footer class=" text-center text-lg-start mt-5">
                <div class="container p-4">
                <div class="row">
                <div class="col-lg-6 col-md-12 mb-4 mb-md-0">
                    <h5 class="text-uppercase">Footer text</h5>

                    <p>
                    Lorem ipsum dolor sit amet consectetur, adipisicing elit. Iste atque ea quis
                    molestias. Fugiat pariatur maxime quis culpa corporis vitae repudiandae aliquam
                    voluptatem veniam, est atque cumque eum delectus sint!
                    </p>
                </div>
                <div class="col-lg-6 col-md-12 mb-4 mb-md-0">
                    <h5 class="text-uppercase">Footer text</h5>

                    <p>
                    Lorem ipsum dolor sit amet consectetur, adipisicing elit. Iste atque ea quis
                    molestias. Fugiat pariatur maxime quis culpa corporis vitae repudiandae aliquam
                    voluptatem veniam, est atque cumque eum delectus sint!
                    </p>
                </div>
                </div>
                </div>
                <div class="text-center p-3">
                    © 2020 Copyright:
                    <a class="text-body" href="https://mdbootstrap.com/">MDBootstrap.com</a>
                </div>
            </footer>
        </div>
    </div>
    
    </>
    )
}

export default Home;