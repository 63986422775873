import  "./IPoreiaMou.css"
import RoadMapItem from './RoadMapItem';
import { roadmapData } from './RoadmapData';

const IPoreiaMou = () => {
    const donateBtnStyle ={
        padding:"10px",
        borderRadius:"20px",
        borderColor:"pink",
        backgroundColor:"pink",

    }
    const menuShrinkTransition=(e)=>{
        document.getElementById("PricingBtn").style.display="block"
        document.getElementById("DisabledBtn").style.display="block"
    }
    const menuExpandTransition=(e)=>{
        document.getElementById("PricingBtn").style.display="none"
        document.getElementById("DisabledBtn").style.display="none"
    }
    
    
    const hiddenBtn={
       display:"none"
    }
  return (
    <>
    <div class="bodyContainer">

        <div class="headerContainer text-center">
            <h1 class="pageTitle">Η πορεία μου </h1>
            <nav class="navbar navbar-expand-lg navbar-light d-flex flex-row-reverse"  id="menuBar">
                <div class="navbar-nav"  onMouseEnter={menuShrinkTransition} onMouseLeave={menuExpandTransition}>
                    <a class="nav-item nav-link active" id="homeBtn" href="/">Αρχική <span class="sr-only">(current)</span></a>
                    <a class="nav-item nav-link" id="iPoreiaMouBtn" href="/IPoreiaMou">Η πορεία μου</a>
                    <a class="nav-item nav-link" id="PricingBtn" href="/" style={hiddenBtn}>Pricing</a>
                    <a class="nav-item nav-link" id="DisabledBtn" href="/" style={hiddenBtn}>Disabled</a>
                    <a class="nav-item nav-link" id="donateBtn" style={donateBtnStyle}  href="/Donate"> Donate</a>
                </div>
            </nav> 
        </div>
        <div class="homeContainer">
            <div className="container">
                <section className="roadmap">
                    <div className="roadmap__timeline">
                    <div className="roadmap__line"></div>
                    {roadmapData.map((item, index) => (
                        <RoadMapItem
                        key={item.id}
                        id={item.id}
                        stage={item.stage}
                        details={item.details}
                        isEven={index % 2 === 0}
                        img={item.img}
                        />
                    ))}
                    </div>
                </section>
                
            </div>
            <footer class="bg-body-tertiary text-center text-lg-start">
                <div class="container p-4">
                <div class="row">
                <div class="col-lg-6 col-md-12 mb-4 mb-md-0">
                    <h5 class="text-uppercase">Footer text</h5>

                    <p>
                    Lorem ipsum dolor sit amet consectetur, adipisicing elit. Iste atque ea quis
                    molestias. Fugiat pariatur maxime quis culpa corporis vitae repudiandae aliquam
                    voluptatem veniam, est atque cumque eum delectus sint!
                    </p>
                </div>
                <div class="col-lg-6 col-md-12 mb-4 mb-md-0">
                    <h5 class="text-uppercase">Footer text</h5>

                    <p>
                    Lorem ipsum dolor sit amet consectetur, adipisicing elit. Iste atque ea quis
                    molestias. Fugiat pariatur maxime quis culpa corporis vitae repudiandae aliquam
                    voluptatem veniam, est atque cumque eum delectus sint!
                    </p>
                </div>
                </div>
            </div>
            <div class="text-center p-3">
                © 2020 Copyright:
                <a class="text-body" href="https://mdbootstrap.com/">MDBootstrap.com</a>
            </div>
        </footer>
        </div>
    </div>
  </>
  );
};

export default IPoreiaMou;
