import  "./Panellhnio2021.css"

const Panellhnio2021 = ()=>{
    const backgroundImageStyle ={
        position:"absolute",
        width:"100%",
        top:"0px",
        zIndex:"-1"
    }
    const donateBtnStyle ={
        padding:"10px",
        borderRadius:"20px",
        borderColor:"pink",
        backgroundColor:"pink",

    }
    const menuShrinkTransition=(e)=>{
        document.getElementById("PricingBtn").style.display="block"
        document.getElementById("DisabledBtn").style.display="block"
    }
    const menuExpandTransition=(e)=>{
        document.getElementById("PricingBtn").style.display="none"
        document.getElementById("DisabledBtn").style.display="none"
    }
    
    const hiddenBtn={
       display:"none"
    }
    return (
    <>
    <div>
        <nav class="navbar navbar-expand-lg navbar-light d-flex flex-row-reverse" >
                <div class="navbar-nav"  id="menuBar" onMouseEnter={menuShrinkTransition} onMouseLeave={menuExpandTransition}>
                    <a class="nav-item nav-link active" id="homeBtn" href="/">Αρχική <span class="sr-only">(current)</span></a>
                    <a class="nav-item nav-link" id="iPoreiaMouBtn" href="/IPoreiaMou">Η πορεία μου</a>
                    <a class="nav-item nav-link" id="PricingBtn" href="/" style={hiddenBtn}>Pricing</a>
                    <a class="nav-item nav-link" id="DisabledBtn" href="/" style={hiddenBtn}>Disabled</a>
                    <a class="nav-item nav-link" id="donateBtn" style={donateBtnStyle}  href="/Donate"> Donate</a>
                </div>
        </nav> 
        <div class="logo" >
            <img src="/images/Home/SingaporeSmash.jpeg" alt="Singapore Smash" style={backgroundImageStyle}/> 
            <div class="logoContainer">
                <h1 class="logoName">Καΐπη Μαρία</h1>
                <br/>
                <p>mpla mpla mpla mpla mpla mplampla mpla mpla mpla mpla mplampla mpla mpla mpla mpla mplampla mpla mpla mpla mpla mplampla mpla mpla mpla mpla mpla</p>
            </div>
        </div>
    </div>
    </>
    )
}

export default Panellhnio2021;