import  "./Montenegrin2022.css"

const Montenegrin2022 = ()=>{
    const backgroundImageStyle ={
        position:"absolute",
        width:"100%",
        top:"0px",
        zIndex:"-1"
    }
    const donateBtnStyle ={
        padding:"10px",
        borderRadius:"20px",
        borderColor:"pink",
        backgroundColor:"pink",

    }
    const menuShrinkTransition=(e)=>{
        document.getElementById("PricingBtn").style.display="block"
        document.getElementById("DisabledBtn").style.display="block"
    }
    const menuExpandTransition=(e)=>{
        document.getElementById("PricingBtn").style.display="none"
        document.getElementById("DisabledBtn").style.display="none"
    }
    
    const selectTournament=(e)=>{
        console.log(e)
        window.location.href = "/tournaments/"+e.target.id;

    }
    
    const hiddenBtn={
       display:"none"
    }
    return (
    <>        
    <div class="">
        <div class="headerContainer">
            <nav class="navbar navbar-expand-lg navbar-light d-flex flex-row-reverse"  id="menuBar">
                <div class="navbar-nav"  onMouseEnter={menuShrinkTransition} onMouseLeave={menuExpandTransition}>
                    <a class="nav-item nav-link active" id="homeBtn" href="/">Αρχική <span class="sr-only">(current)</span></a>
                    <a class="nav-item nav-link" id="iPoreiaMouBtn" href="/IPoreiaMou">Η πορεία μου</a>
                    <a class="nav-item nav-link" id="PricingBtn" href="/" style={hiddenBtn}>Pricing</a>
                    <a class="nav-item nav-link" id="DisabledBtn" href="/" style={hiddenBtn}>Disabled</a>
                    <a class="nav-item nav-link" id="donateBtn" style={donateBtnStyle}  href="/Donate"> Donate</a>
                </div>
            </nav> 
            <h1 class="pageTitle">Montenegrin Para 2022 Tournament </h1>
        </div>
        <div class="pageContainer">
            
        </div>
    </div>
    </>
    )
}

export default Montenegrin2022;