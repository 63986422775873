export const roadmapData = [
  {
    id: "SingaporeSmashEvent2024",
    stage: 'Singapore Smash Event 2024',
    details: [
      'Singapore',
    ],
    img:"SingaporeSmash"
  },
  {
    id: 'Finland2023',
    stage: 'Finland 2023',
    details: [
      'Finland EPYG 2023',
    ],
    img:"Finland2023"
  },
  {
    id: "GreekParaOpen2023",
    stage: 'Greek Para Open 2023',
    details: [
      'Greek Para Open 2023',
    ],
    img:"GreekParaOpen2023"
  },
  {
    id: "GreekParaOpen2022",
    stage:  'Greek Para Open 2022',
    details: [
      'Greek Para Open 2022',
    ],
    img:"GreekParaOpen2022"
  },
  {
    id: "CzechParaOpen2022",
    stage: 'Czech Para Open 2022',
    details: [
      'Czech Para Open 2022',
      'Global Expansion Initiatives',
      'Launch of TechNova Enterprise Solutions',
      'Continued User Growth and Engagement',
    ],
    img:"CzechParaOpen2022"
  },
  {
    id: "MontenegrinParaChampionships2022",
    stage: 'Montenegrin Para Championships 2022',
    details: [
      'Montenegrin (Podgoriza) 2022',
    ],
    img:"MontenegrinParaChampionships2022"
  },
];
