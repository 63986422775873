import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './App.css';
import Home from './pages/Home/Home';
import IPoreiaMou from './pages/IPoreiaMou/IPoreiaMou';
import Panellhnio2021 from './pages/Panellhnia/2021/Panellhnio2021';
import Panellhnio2022 from './pages/Panellhnia/2022/Panellhnio2022';
import Panellhnio2023 from './pages/Panellhnia/2023/Panellhnio2023';
import Czech2022 from './pages/IPoreiaMou/tournaments/Czech2022/Czech2022';
import Greek2022 from './pages/IPoreiaMou/tournaments/Greek2022/Greek2022';
import Greek2023 from './pages/IPoreiaMou/tournaments/Greek2023/Greek2023';
import Finland2023 from './pages/IPoreiaMou/tournaments/Finland2023/Finland2023';
import SingaporeSmashEvent2024 from './pages/IPoreiaMou/tournaments/SingaporeSmashEvent2024/SingaporeSmashEvent2024';
import Montenegrin2022 from './pages/IPoreiaMou/tournaments/Montenegrin2022/Montenegrin2022';
import Donate from './pages/Donate/Donate';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.js';

function App() {
  return (
   <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/IPoreiaMou" element={<IPoreiaMou />} />
        <Route path="/Donate" element={<Donate />} />
        <Route path="/Panellhnia/Panellhnio2023" element={<Panellhnio2023 />} />
        <Route path="/Panellhnia/Panellhnio2022" element={<Panellhnio2022 />} />
        <Route path="/Panellhnia/Panellhnio2021" element={<Panellhnio2021 />} />
        <Route path="/IPoreiaMou/tournaments/SingaporeSmashEvent2024" element={<SingaporeSmashEvent2024 />} />
        <Route path="/IPoreiaMou/tournaments/Finland2023" element={<Finland2023 />} />
        <Route path="/IPoreiaMou/tournaments/GreekParaOpen2023" element={<Greek2023 />} />
        <Route path="/IPoreiaMou/tournaments/GreekParaOpen2022" element={<Greek2022 />} />
        <Route path="/IPoreiaMou/tournaments/CzechParaOpen2022" element={<Czech2022 />} />
        <Route path="/IPoreiaMou/tournaments/MontenegrinParaChampionships2022" element={<Montenegrin2022 />} />
      </Routes>
   </BrowserRouter>
  );
}

export default App;
