import React from "react";
import { StackedCarouselSlideProps } from "react-stacked-center-carousel";
import "./Slide.css";

export const Slide = React.memo(function (StackedCarouselSlideProps) {
  const {
    data,
    dataIndex,
    isCenterSlide,
    swipeTo,
    slideIndex
  } = StackedCarouselSlideProps;

  const coverImage = data[dataIndex].image;
  const text = data[dataIndex].text;
  const imageId = data[dataIndex].id;

  console.log(coverImage);

  const cardClick=(e)=>{
    if(e.target.parentNode.parentNode.parentNode.parentNode.className=="react-stacked-center-carousel-slide-0"){
      window.location.href="/Panellhnia/"+imageId
    }
  }

  return (
    <div className="card-card" draggable={false} onClick={cardClick} style={{cursor:"pointer"}}>
      <div className={`cover fill ${isCenterSlide ? "off" : "on"}`}>
        <div
          className="card-overlay fill"
          onClick={() => {
            if (!isCenterSlide) swipeTo(slideIndex);
          }}
        />
      </div>
      <div className="detail fill justify-content-center">
        <div className="discription justify-content-center ">
          <img
            style={{ height: 300,padding:"1rem" }}
            className="cover-image"
            src={coverImage}
            id={imageId}
          />
          <p class="text-center">{text}</p>
        </div>
      </div>
    </div>
  );
});
